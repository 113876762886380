var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingSwapCompany },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.updateCurrentCompany },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: {
                click: function () {
                  this$1.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "white--text text-h5 mb-4",
                  attrs: { color: "secondary" },
                },
                [_vm._v(" User Swap Company ")]
              ),
              _vm._l(_vm.users, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "v-card",
                      { staticClass: "ma-5 pa-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start mx-2 mt-4" },
                          [
                            _c(
                              "p",
                              { staticClass: "text-h5 font-weight-bold" },
                              [_vm._v(" User " + _vm._s(index + 1) + " ")]
                            ),
                          ]
                        ),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                              _c("div", { staticClass: "px-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "pr-3" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "First Name",
                                        dense: "",
                                        outlined: "",
                                        disabled: "",
                                      },
                                      model: {
                                        value: item.user_name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "user_name", $$v)
                                        },
                                        expression: "item.user_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Last Name",
                                        dense: "",
                                        outlined: "",
                                        disabled: "",
                                      },
                                      model: {
                                        value: item.user_lname,
                                        callback: function ($$v) {
                                          _vm.$set(item, "user_lname", $$v)
                                        },
                                        expression: "item.user_lname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        disabled: "",
                                        label: "Email",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: item.user_email,
                                        callback: function ($$v) {
                                          _vm.$set(item, "user_email", $$v)
                                        },
                                        expression: "item.user_email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex px-2" },
                          [
                            _c("v-text-field", {
                              attrs: { disabled: "", dense: "", outlined: "" },
                              model: {
                                value: _vm.companies,
                                callback: function ($$v) {
                                  _vm.companies = $$v
                                },
                                expression: "companies",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.companiesAvailable != null
                          ? _c(
                              "div",
                              { staticClass: "d-flex px-2" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    items: _vm.companiesAvailable,
                                    label: "Swap Company",
                                    "return-object": "",
                                    "item-text": "tempName",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validateInputUpdateCurrentCompany(
                                        item.user_id
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.companiesAvailableSelected,
                                    callback: function ($$v) {
                                      _vm.companiesAvailableSelected = $$v
                                    },
                                    expression: "companiesAvailableSelected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-end pa-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function () {
                          this$1.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          this$1.isPending = true
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }